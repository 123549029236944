import { ContentData, PaginationType, } from 'types';
import { create } from 'zustand';

export type USER = {
    _id: string,
    label: string,
    value: string,
    isRecommeded:boolean,
    preferredFname:string,
    preferredLname:string
  } & (MenteeMentorType | PairType | GroupType)
  
  type MenteeMentorType = {
    role: "Mentee" | "Mentor",
    profilePic: string
  }
  
  type PairType = {
    role: "Pair",
    profilePic: { menteeProfilePic: string, mentorProfilePic: string }
  }
  
  type GroupType = {
    role: 'Group',
    profilePic: null
  }

interface useContentUserStore {

    tab: string;
    search: string;
    sort: {
        headerType: string,
        sortType: string
    };
    filterModal: boolean;
    assignedCourseUsers: { assignedPartners: any; assignedRegions: any; } | null;
    isMakeAvailableToLoading: boolean;
    makeAvailableTo: any;
    isArchived: boolean;

    label: string;

    removeContentUser: any;
    archiveContentUser: any;

    selectAll: boolean;
    multiSelectArray: string[];

    multiSelectUserIdsArray: USER[],

    isLoadingRecomendedToUser: boolean;
    recomendedToUser: any;
    recommandUserList: USER[];

    initialContentResponse: any
    pagination: PaginationType
    isContentLoading: boolean
    isCreateContentLoader: boolean
    viewCourse:string;
    setState(nextState: Partial<useContentUserStore>): void
}

export const initialContentFilterState = {
    search: '',
    filterModal: false,
    isArchived: false,
    sort: {
        headerType: '',
        sortType: ''
    },
    pagination: {
      limit: 50,
      page: 1,
      pages: 1,
      total: 0
    },
}

export const initialContentUserState = {
    ...initialContentFilterState,
    tab: 'Content',
    assignedCourseUsers: null,
    isMakeAvailableToLoading: false,
    makeAvailableTo: null,

    label: '',

    removeContentUser: null,
    archiveContentUser: null,

    selectAll: false,
    multiSelectArray: [],

    multiSelectUserIdsArray: [],

    isLoadingRecomendedToUser: false,
    recomendedToUser: null,
    recommandUserList: [],
    viewCourse:'',
    initialContentResponse: [],
    isContentLoading: false,
    isCreateContentLoader: false,
}

const useContentStore = create<useContentUserStore>((set) => ({
    ...initialContentUserState,

    setState: (nextState) => set(() => ({ ...nextState }))
}));


export default useContentStore;