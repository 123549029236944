import { useState } from 'react';
import axiosClient from "api/axiosClient";
import { cookieDecryption, decryption, expireTime } from 'utils';
import useToaster from 'hooks/store/toast/useToaster';
import Cookies from 'universal-cookie';
import { useNavigate } from "react-router-dom";
import useHeader from 'hooks/store/common/useHeader';
import { getSocket } from 'socket/WebSocket';
import useSocketStore from 'hooks/store/messages/useSocketStore';
import useLoginStore from "../../store/common/useLoginStore";

interface loginUserReq {
    userId: string,
    masterUserId: string;
}

const initialState = {
    isLoading: false,
    isSuccess: false,
    isError: false,
    data: null,
    error: null
}

export default function useLoginAs() {
    const toaster = useToaster();
    const { setState } = useHeader();
    const cookies = new Cookies();
    const navigate = useNavigate();
    const sokectData = useSocketStore().getState();
    const { setState: setUpdate } = useLoginStore();

    const loginAsUser = async (req: loginUserReq, match: boolean) => {

        // setState({ ...state,isLoading: true });
        // setUpdate({ loginAsModal: null });
        sokectData.socket.close()
        try {
            const data = await axiosClient.post('/admin/loginAs', req).then((res) => res.data.data);

            if (!data.error) {



                if (match) {

                    // cookies.set('user', data.data, { domain: process.env.REACT_COOKIE_DOMAIN, path: '/', expires: expireTime(),});
                    cookies.set('user', data.data, { path: '/', expires: expireTime() });

                    const user = decryption(data.data)
                    sessionStorage.setItem('userProfilePic', user.profilePic)
                    setUpdate({ loginResponse: decryption(data.data)});
                    // getSocket({ token: user?.token, userData: user });
                    sokectData.socket.emit("join",{ token: user?.token,  user_id: user?.userId, } );
                    sokectData.setState({connectionList:[]})
                    navigate('/switch-account', { state: { user } });

                }
                return;
            }

            toaster.showToast('Danger', data.msg);

        } catch (error: any) {
            if(error.response.status !== 401 ){
                toaster.showToast('Danger', error.response.data.msg)
            }

        } finally {

        }
    }

    return {
        loginAsUser,
    }
}
