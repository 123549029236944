import { ROLE } from "static";
import { optionsProps, PaginationType } from "types";
import { cookieDecryption } from "utils";
import { create } from "zustand";

interface Sort {
  headerType: string;
  sortType: string;
}
export interface SOMType {
  label: string;
  value: string;
  min: number;
  max: number;
}
interface ApproveEventsData {
  eventId: string;
  flag: number;
}
interface AttachmentUpload {
  key: string;
  localtion: string;
}
interface useEventsStore {
  tab: string;
  search: string;
  eventDetailsData: any | null;
  pagination: PaginationType;
  sort: Sort;
  guestShort:any;
  fileData: any;
  initialEventsResponse: Object[];
  setState(nextState: Partial<useEventsStore>): void;
  approveEvents:ApproveEventsData|null;
  isRejectedEvents:boolean,
  isRejectedAllEvents:boolean,
  isRejectedPending:boolean,
  attachmentResponse:AttachmentUpload|null,
  thubNailResponse:AttachmentUpload|null,
  guestListArray:any,
  guestList:any,
  seletedGuestList:any,
  calenderDate:any,
  createEventsData:any,
  editedData:any,
  contiune:boolean|null,
  isDelete:boolean|null,
  eventId:null|string,
  addGuest:boolean,
  eventsForFullCalendar:any,
  popUpData:any,
  btnLoader:boolean,
  ThumbnailUpload:boolean,
  dateArrayAllEvents:any,
  attachmnetUpload:boolean,
  attendancePayload:any,
  invitationPayload:any,
  guestSearch:any,
  guestData:any,
  addEvent:any,
  guestPayLoad:any,
  guestListLoader:any,
  displayGuestList:any,
  filterModal:boolean,
  filterModalGuest:boolean,
  isDisable:boolean,
  filterOptions:any,
  groupFilterValue:any,
  attendanceType:optionsProps,
  invitationType:optionsProps,
  eventDetailPageCouts:any,
  eventThumbnailIcons:any,
  eventAttendanceResId:string,
  invitationResId:string,
  setGuestSearch(search: string): void;
  setSearch(search: string): void;
}
const { I_SUPER_ADMIN } = ROLE;
const user = cookieDecryption("user");
const useEvents = create<useEventsStore>((set) => ({
  tab: user?.role === I_SUPER_ADMIN ? "All Events" : "My Events",
  setSearch: (search: string) => set({ search }),
  setGuestSearch: (search: string) => set({ search }),
  guestListArray: null,
  search: "",
  sort: {
    headerType: "",
    sortType: "",
  },
  guestShort:{
    headerType: "",
    sortType: "",
  },
  addGuest:false,
  approveEvents:null,
  eventsForFullCalendar:null,
  createEventsData:null,
  isRejectedEvents:false,
  isRejectedAllEvents:false,
  isRejectedPending:false,
  attachmentResponse:null,
  calenderDate:null,
  dateArrayAllEvents:null,
  thubNailResponse:null,
  eventDetailsData:null,
  filterModalGuest:false,
  isDisable:false,
  guestList:null,
  seletedGuestList:null,
  fileData:null,
  editedData:null,
  isDelete:null,
  eventId:null,
  contiune:null,
  popUpData:null,
  btnLoader:false,
  addEvent:false,
  ThumbnailUpload:false,
  attachmnetUpload:false,
  attendancePayload:null,
  invitationPayload:null,
  displayGuestList:null,
  guestPayLoad:null,
  guestSearch:null,
  guestData:null,
  guestListLoader:"",
  attendanceType :{ label: '', value: '' },
  invitationType :{ label: '', value: '' },
  eventDetailPageCouts:{
    RSVPYes:null,
    RSVPNo:null,
    noResponse:null,
    totalInvites:null
  },
  pagination: {
    limit: 50,
    page: 1,
    pages: 0,
    total: -1,
  },
  eventAttendanceResId:'',
  invitationResId:'',
  filterModal: false,
  filterOptions: [],
  groupFilterValue: [],
  initialEventsResponse: [],
  eventThumbnailIcons: [],
  setState: (nextState) => set(() => ({ ...nextState })),
}));

export default useEvents;