import socketIo from "socket.io-client";
import { Encryption, cookieDecryption, decryption, replaceShortNameAtIndex } from "../../../utils";
import useSocketStore from "../../store/messages/useSocketStore";
import useLoginStore from "hooks/store/common/useLoginStore";

const requestHandler = (event: string, data: any) => {
  switch (event) {
    case "join": {
      return data;
    }
    default: {
      return { data, event };
    }
  }
};

export default function useSocket() {
  const socketData = useSocketStore();
  const { setState: setSessionExpire } = useLoginStore();
  const { setState } = socketData;

  async function getSocket({ token, userData }: any) {
    const socket: any = socketIo(process.env.REACT_APP_SOCKET_POINT, {
      transports: ["websocket"],
    });

    setState({
      ...socketData.getState(),
      socket,
    });

    socket.on("connect", () => {
      const payload = {
        event: "join",
        data: {
          user_id: userData?.userId,
          token: token,
        },
      };

      // socket.emit(
      //   "join",
      //   process.env.REACT_APP_DISABLE_ENCRYPTION === "true"
      //     ? requestHandler("join", payload.data)
      //     : Encryption(requestHandler("join", payload.data))
      // );
      sendEvent({ payload });
    });
  }

  async function sendEvent({ payload, changeLoader }: any) {
    const { event, data } = payload;

    const { socket } = socketData.getState();
    if (!changeLoader) {
      setState({
        ...socketData.getState(),
        socketLoaders: {
          ...socketData?.getState()?.socketLoaders,
          [event]: true,
        },
      });
    } else {
      setState({
        ...socketData.getState(),
        socketLoaders: {
          ...socketData?.getState()?.socketLoaders,
          [changeLoader]: true,
        },
      });
    }

    if (!socket?.connected) {
      return;
    }
    socket.emit(
      event === "join" ? "join" : "request",
      process.env.REACT_APP_DISABLE_ENCRYPTION === "true"
        ? requestHandler(event, data)
        : Encryption(requestHandler(event, data))
    );
  }

  async function socketResponse({ response }: any) {
    setState({
      ...socketData.getState(),
      socketLoaders: {
        ...socketData?.getState()?.socketLoaders,
        [response?.event]: false,
        chatLoader: false,
      },
    });
    const currentUrl = window.location.href;
    const url = new URL(currentUrl);
    const queryString = url.search;
    const params = new URLSearchParams(queryString);
    const paramValue: any = params.get("query");
    const query = decryption(paramValue);
    switch (response?.event) {
      case "join": {
        const payload = {
          event: "getCounts",
          data: {},
        };

        setState({
          ...socketData.getState(),
          socketConnected: true,
        });

        await sendEvent({ payload });
        break;
      }
      case "partnerShortNameUpdate": {
        if (currentUrl?.includes('team-inbox') && currentUrl?.match(/team-inbox\/([^?]+)/)?.[1]?.length == 24) {
          const groupId = currentUrl?.match(/team-inbox\/([^?]+)/)?.[1];
          const connectionlist = socketData.getState()?.connectionList;
          const conversationlist = socketData.getState()?.messageList?.messageData;

          const index = connectionlist?.findIndex((item: any) => item?.group?._id === groupId);
          const uniqueChats = conversationlist.reduce((acc: any, current: any) => {
            const x = acc.find((item: any) => item._id === current._id);
            if (!x) {
              if (current.messageType === "Welcome Message" && current.messageCategory === "Welcome Prompt") {
                acc.push({
                  ...current,
                  message: current?.content?.replace(':attributes', response?.data?.shortName),
                  groupName: response?.data?.shortName
                })
              } else {
                acc.push({
                  ...current,
                  groupName: response?.data?.shortName
                });
              }
            }
            return acc;
          }, []);

          if (index !== -1) {
            const updatedGroup = {
              ...connectionlist[index].group,
              groupName: response?.data?.shortName
            };
            connectionlist[index] = {
              ...connectionlist[index],
              group: updatedGroup
            };
          }

          setState({
            connectionlist: connectionlist,
            messageList: { messageData: uniqueChats }
          });
        } else {
          const connectionlist = socketData.getState()?.connectionList;

          const index = connectionlist?.findIndex((item: any) => item?.group?._id === response?.data?.groupId);

          if (index !== -1) {
            const updatedGroup = {
              ...connectionlist[index].group,
              groupName: response?.data?.shortName
            };
            connectionlist[index] = {
              ...connectionlist[index],
              group: updatedGroup
            };
          }

          setState({
            connectionlist: connectionlist
          });
        }

        break;
      }
      case "getMsgList": {
        const userId = socketData.chatId
          ? socketData.chatId
          : window.location.pathname.split("team-inbox/")?.[1];
        const user = cookieDecryption("user");

        const conversationData = response?.data?.docs ?? [];
        const preMessageList = socketData.getState().connectionList ?? [];

        let nextMessageList;
        if (response?.searchResult || response?.groupFiltered) {
          nextMessageList = [...conversationData];
        } else {
          nextMessageList = [...preMessageList, ...conversationData];
        }

        const uniqueChats = nextMessageList.reduce((acc: any, current: any) => {
          const x = acc.find((item: any) => item.chatId === current.chatId);
          if (!x) {
            acc.push(current);
          }
          return acc;
        }, []);

        setState({
          ...socketData.getState(),
          connectionList: uniqueChats,
          connectionListTotalDocs: response?.data?.total,
        });
        let temp = response?.data?.docs?.find(
          (_: any) =>
            _?.lastMessage?.senderId !== user?._id && !_?.lastMessage?.read
        );

        const payload = {
          event: "getMsg",
          data: {
            senderId: userId,
            limit:
              temp?.unreadMessagesCount > 10 ? temp?.unreadMessagesCount : 10,
            ...(query?.group?._id && {
              groupId: query?.group?._id,
            }),
            chatId: query?.chId,
          },
        };
        const isMsg =
          typeof temp?.lastMessage?.senderId === "string"
            ? temp?.lastMessage?.senderId
            : temp?.lastMessage?.sender?._id;
        if (isMsg === userId) {
          const payload = {
            event: "readMsg",
            data: { chatId: temp?.chatId, senderId: isMsg },
          };
          sendEvent({ payload });
        }
        if (userId) {
          sendEvent({ payload });
        }
        break;
      }
      case "getMsg": {
        const userId = socketData.chatId
          ? socketData.chatId
          : window.location.pathname.split("team-inbox/")?.[1];
        const user = cookieDecryption("user");
        let temp = response?.data?.conversationlist?.data?.find(
          (_: any) => _?.senderId !== user?._id
        );
        // debugger
        const isMsg =
          typeof temp?.senderId === "string"
            ? temp?.senderId
            : temp?.sender?._id;

        if (isMsg !== userId) {
          const payload = {
            event: "readMsg",
            data: { chatId: temp?.chatId, senderId: isMsg },
          };
          const newConnectionList = socketData.getState()?.connectionList?.map((data: any) => {
            if (data?.chatId === temp?.chatId) {
              return { ...data, unreadMessagesCount: 0 };
            }
            return data;
          })
          sendEvent({ payload });
          setState({
            ...socketData.getState(),
            connectionList: newConnectionList,
          });
        }
        const conversationData =
          response?.data?.conversationlist?.data?.reverse() ?? [];
        const preMessageList =
          socketData.getState().messageList?.messageData ?? [];
        const nextMessageList = [...conversationData, ...preMessageList];

        const netMessageObj = {
          ...response?.data?.conversationlist,
          messageData: nextMessageList,
        };

        setState({
          ...socketData.getState(),
          messageList: { ...netMessageObj },
        });
        break;
      }
      case "partnerShortNameUpdate": {
        const currentPath = window.location.pathname;
        if (currentPath?.includes("team-inbox")) {
          const connectionList = socketData.getState()?.connectionList;
          const index = connectionList?.findIndex(
            (item: any) => item?.group?._id === response?.data?.groupId
          );
          const updatedList = replaceShortNameAtIndex(connectionList, response?.data?.shortName, index);

          setState({
            connectionList: updatedList,
          });
        }
        if (
          currentPath?.includes("team-inbox") &&
          currentPath?.split("team-inbox/")?.[1]?.length === 24
        ) {
          const groupId = currentPath?.split("team-inbox/")?.[1];
          const messageList = socketData.getState()?.messageList?.messageData;

          const uniqueChats = messageList?.reduce((acc: any, current: any) => {
            const x = acc.find((item: any) => item._id === current._id);
            if (!x) {
              if (current?.messageCategory === "Welcome Prompt") {

                acc?.push({
                  ...current,
                  message: current?.content?.replace(
                    ":attributes",
                    response?.data?.shortName
                  ),
                });
              } else {
                acc.push(current);
              }
            }
            return acc;
          }, []);
          setState({
            messageList: { messageData: uniqueChats },
          });
        }
        break;
      }
      case "sendMsg": {
        const userId = socketData.chatId
          ? socketData.chatId
          : window.location.pathname.split("messages/")?.[1] || "";

        const user = cookieDecryption("user");
        let lastData = socketData?.getState();
        const newMessage = response.data.conversationlist;

        let temp = [...lastData.connectionList];
        let index = temp?.findIndex(
          (data) => data?.chatId === newMessage?.chatId
        );
        const currentUrl = window.location.href;

        const url = new URL(currentUrl);
        const queryString = url.search;
        const params = new URLSearchParams(queryString);
        const paramValue: any = params.get("query");
        const query = decryption(paramValue);
        if (index > -1) {
          let latestChat = {
            ...temp[index],
            lastMessage: newMessage,
            unreadMessagesCount:
              userId !== newMessage?.receiver?._id
                ? temp[index]?.unreadMessagesCount + 1
                : 0,
          };
          temp.splice(index, 1);
          temp.unshift(latestChat);
        } else {
          temp.unshift({
            ...newMessage,
            receiver: newMessage?.receiver?._id,
            user:
              newMessage.receiver?._id === user.userId
                ? newMessage.sender
                : newMessage.receiver,
            lastMessage: newMessage,
            unreadMessagesCount: newMessage?.lastMessage?.isRead ? 0 : 1,
          });
        }
        
        let latest = {
          ...lastData,
          connectionList: temp,
        };
        if (query?.group && query?.group?._id === newMessage?.group?._id) {
          const payload = {
            event: "readMsg",
            data: { chatId: newMessage?.chatId, senderId: userId },
          };
          sendEvent({ payload });
          const msgState = lastData.messageList || {};
          const updatedData = {
            ...msgState,
            messageData: [...(msgState?.messageData || []), newMessage],
            sendMessage: true,
          };
          latest = {
            ...latest,
            messageList: updatedData,
          };
          let nextUsers = [...latest.connectionList];
          let nextUserIndex = nextUsers.findIndex(
            (data) => data.chId === newMessage.chId
          );
          nextUsers[nextUserIndex] = {
            ...nextUsers[nextUserIndex],
            unreadMessagesCount: 0,
          };
          // socketData.setState({ connectionList:nextUsers })
          setState({
            ...latest,
            connectionList: nextUsers,
          });
          return;
        }
        const msgState = lastData.messageList || {};
        const updatedData = {
          ...msgState,
          messageData: [...(msgState?.messageData || []), newMessage],
          sendMessage: true,
        };
        latest = {
          ...latest,
          messageList: updatedData,
        };

        setState({
          ...latest,
        });

        break;
      }
      case "getNotifications": {
        setState({ notificationResponse: response.data });
        break;
      }
      case "sendMsgReaction": {
        let lastData = socketData?.getState();
        const currentUser = cookieDecryption("user");

        let data = response?.data;
        const messageList = lastData?.messageList || {};

        let messageData = messageList?.messageData || [];
        let index = messageList?.messageData?.findIndex(
          (_: any) => _._id === data?.messageId
        );

        // Use the response.reactions array to update the newReactions
        let updatedReactions = (message:any) => {
          return message.reactions
            .filter((reaction:any) => {
              if (reaction.type === data.reaction) {
                return reaction.users.some(
                  (user:any) => user._id !== currentUser.userId
                );
              }
              return true;
            })
            .map((reaction:any) => {
              if (reaction.type === data.reaction) {
                return {
                  ...reaction,
                  count: --reaction.count,
                  userReacted: false,
                  users: reaction.users.filter(
                    (user:any) => user._id !== currentUser.userId
                  ),
                };
              }
              return { ...reaction };
            });
        };
        if (data.messageId !== response?.lastMessage?._id) {
          messageData[index] = {
            ...messageData[index],
            reactions: updatedReactions(messageData[index]),
          };
        } else {
          messageData[index] = {
            ...messageData[index],
            reactions: response?.lastMessage?.reactions || [],
          };
        }
        let updatedData = {
          ...messageList,
          messageData: [...messageData],
          sendReaction: true,
        };
        const newData = lastData?.connectionList?.map((e: any) =>
          e?.lastMessage?.chatId === response?.lastMessage?.chatId
            ? { ...e, lastMessage: { ...response?.lastMessage } }
            : e
        );
        setState({
          ...lastData,
          messageList: updatedData,
          connectionList: newData,
        });

        break;
      }

      case "getCounts": {
        setState({
          getNotificationCount: response?.data?.notificationCount,
        });
        break;
      }
      case "readMsg": {
        let lastData = socketData?.getState();
        let nextResponse = { ...lastData };
        const array = nextResponse?.messageList?.messageData ?? [];
        let nextMessageListArray = [...array].map((message: any) => ({
          ...message,
          read: true,
        }));
        nextResponse = { ...nextResponse, messageData: nextMessageListArray };
        let newConnectionList = lastData?.connectionList?.map((e: any) =>
          e?.chatId === response.data.chatId
            ? { ...e, lastMessage: { ...e?.lastMessage, read: true } }
            : { ...e }
        );

        setState({
          messageList: nextResponse,
          connectionList: newConnectionList,
        });
        return;
      }
      case "readNotification": {
        let nextNotificationResponse =
          socketData.getState()?.notificationResponse;
        const nextNotificationArray = nextNotificationResponse?.data?.map(
          (notification: any) => ({ ...notification, read: true })
        );
        nextNotificationResponse = {
          ...nextNotificationResponse,
          data: nextNotificationArray,
        };

        setState({ notificationResponse: nextNotificationResponse });

        break;
      }
      case "error": {
        setSessionExpire({ isSessionExpired: true });
        break;
      }
    }
  }

  return {
    getSocket,
    sendEvent,
    socketResponse,
  };
}
