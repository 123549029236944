import { create } from 'zustand';

interface useHeaderStore {
    isAddAnotherAccount: boolean;
    loginAsModal : any;
    loginAsResponse: any;
    setIsAddAnotherAccount: () => void;
    setState(nextState: Partial<useHeaderStore>): void;
}

const useHeader = create<useHeaderStore>((set) => ({
    isAddAnotherAccount: false,
    loginAsModal : null,
    loginAsResponse: null,
    setIsAddAnotherAccount: () => set(({isAddAnotherAccount}) => ({ isAddAnotherAccount: !isAddAnotherAccount })),
    setState: (nextState) => set(() => ({ ...nextState })),
}));


export default useHeader;
