import { useReducer, useState } from 'react';
import axiosClient from "api/axiosClient";
import { cookieEncryption } from 'utils';
import useToaster from 'hooks/store/toast/useToaster';
import { useNavigate } from "react-router-dom";

interface useRemoveLoginState {
    isLoading: boolean,
    isSuccess: boolean,
    isError: boolean,
    data: any,
    error: any
}

interface loginUserReq {
    userId: string,
    masterUserId : string
}


const initialState = {
    isLoading: false,
    isSuccess: false,
    isError: false,
    data: null,
    error: null
}

const reducer = (state: useRemoveLoginState, payload: Partial<useRemoveLoginState>) => ({ ...state, ...payload });

export default function useRemoveLogin() {
    const [state, setState] = useReducer(reducer, initialState);
    const toaster = useToaster();

    const removeUser = async (req: loginUserReq) => {
        setState({ ...state,isLoading: true })
        try {
            const data = await axiosClient.post('/admin/remove/loggedInUser', req).then((res) => res.data);
            if (!data.error) {
                toaster.showToast('Success', data.msg)
                setState({ isSuccess: true, data: data });
                return data.data;
            }
            toaster.showToast('Danger', data.msg)
        } catch (error : any) {
            if(error.response.status !== 401 ){
                toaster.showToast('Danger', error.response.data.msg)
            }
            setState({ isError: true, error : error });
        }finally {
            setState({  isLoading: false });
        }
    }

    return {
        ...state,
        removeUser,
    }
}
