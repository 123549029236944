import axios from "axios";
import Cookies from 'universal-cookie';
import { cookieDecryption, expireTime } from "utils";
import useLoginStore from "../hooks/store/common/useLoginStore";

const cookies = new Cookies();


const axiosClient = axios.create({
    baseURL: process.env.REACT_APP_END_POINT,
});

axiosClient.defaults.timeout = 600000;

axiosClient.interceptors.response.use(
    function (response) {
        // if(response?.data?.msg === 'Access denied. Token not found'){
        //     useLoginStore.setState({ isSessionExpired:true })
        // }
        if (response.data?.code === 401) {
            useLoginStore.setState({ isSessionExpired:true })
            // cookies.remove('user', { path: '/', expires: expireTime()});
            // window.location.reload();
        }
        return response;
    },
    function (error) {
        let res = error.response;
        if (res?.status === 401) {
            useLoginStore.setState({ isSessionExpired:true })
            // cookies.remove('user', { path: '/', expires: expireTime()});
            // window.location.reload();
        }
        console.error("Looks like there was a problem. Status Code:" + res?.status);
        return Promise.reject(error);
    }
);

axiosClient.interceptors.request.use(function (config) {
    config.headers.ipAddress = localStorage.getItem("ipAdd")
    // config.headers.ipInformation = JSON.stringify(cookieDecryption('ipInformation'))
    config.headers.ipInformation = localStorage.getItem('ipInformation')
    const masterUser = cookieDecryption('masterUser')
    const masterToken = masterUser?.token
    config.headers['x-auth-masterToken'] = masterToken || '';
    const auth = cookieDecryption('user');
    const token = auth?.token;
    config.headers['x-auth-token'] = token || '';
    return config;
});

export default axiosClient;