import { useReducer, useState } from "react";
import axiosClient from "api/axiosClient";
import useToaster from "hooks/store/toast/useToaster";
import useAdminUsers from "hooks/store/admin-users/useAdminUsers";
import Cookies from "universal-cookie";
import { cookieDecryption, cookieEncryption, decryption } from "utils";

interface useGetUserState {
  isLoading: boolean;
  isSuccess: boolean;
  isError: boolean;
  data: any;
  error: any;
}

const initialState = {
  isLoading: true,
  isSuccess: false,
  isError: false,
  data: null,
  error: null,
};

const reducer = (
  state: useGetUserState,
  payload: Partial<useGetUserState>
) => ({ ...state, ...payload });

export default function useUpdateProfile() {
  const [state, setState] = useReducer(reducer, initialState);
  const { settingPopup, setState: setUpdate } = useAdminUsers();
  const cookies = new Cookies();
  const toaster = useToaster();

  const updateUserProfile = async (req: any) => {
    setState({ ...state, isLoading: true });
    try {
      const data = await axiosClient
        .post("/admin/update/profileDetails", req)
        .then((res) => res.data);
      if (!data.error) {
        let currentUser = cookieDecryption("user");
        currentUser.fullName = `${data.data.legalFname} ${data.data.legalLname}`;
        cookieEncryption("user", currentUser);
        setState({ isSuccess: true, data: data });
        toaster.showToast("Success", data.msg);
        return;
      }
      toaster.showToast("Danger", data.msg);
    } catch (error: any) {
      setState({ isError: true, error });
    } finally {
      setUpdate({ settingPopup: !settingPopup });
    }
  };

  const updateUserProfilePic = async (req: any) => {
    setState({ ...state, isLoading: true });
    try {
      const data = await axiosClient
        .post("admin/uploadProfilePicForAdmin", req)
        .then((res) => res.data);
      if (!data.error) {
        setUpdate({ userProfilePic: data?.data?.profilePic });
      }
      toaster.showToast("Success", 'Profile picture has been updated successfully');
    } catch (error: any) {
      setState({ isError: true, error });
    } finally {
      setUpdate({ settingPopup: !settingPopup });
    }
  };

  return {
    ...state,
    updateUserProfile,
    updateUserProfilePic,
  };
}
