import { useState, useEffect, memo } from 'react';
import { ToastProps } from 'types';
import { Draft } from 'utils/images';

interface ToastComponentProps {
    toastList: ToastProps[],
    autoDelete: boolean,
    autoDeleteTime: number
}

const Toast = (props: ToastComponentProps): JSX.Element => {
    const { toastList, autoDelete, autoDeleteTime } = props;
    const [list, setList] = useState(toastList);
    useEffect(() => {
        setList([...toastList]);
    }, [toastList]);

    useEffect(() => {
        const interval = setInterval(() => {
            if (autoDelete && toastList.length && list.length) {
                deleteToast(toastList[0].id);
            }
        }, autoDeleteTime);

        return () => {
            clearInterval(interval);
        }
    }, [toastList, autoDelete, autoDeleteTime, list]);

    const deleteToast = (id: number) => {
        const listItemIndex = list.findIndex((e: ToastProps) => e.id === id);
        const toastListItem = toastList.findIndex((e: ToastProps) => e.id === id);
        list.splice(listItemIndex, 1);
        toastList.splice(toastListItem, 1);
        setList([...list]);
    }

    return (
        <>
            <div className='toster_waper'>
                {list.map((toast: ToastProps, i: number) =>
                    <div className={`msgbox toster ${toast.title}`}>
                        <div className="msgwrapper flex flex-center flex-between">
                            <div className="msg-text flex flex-center flex-between">
                                {/* green right svg ⬇ */}
                                {toast.title === 'Success' && <svg width="24" height="24" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20ZM14.592 7.46049C14.8463 7.13353 14.7874 6.66232 14.4605 6.40802C14.1335 6.15372 13.6623 6.21262 13.408 6.53958L9.40099 11.6914C9.31189 11.806 9.14429 11.8209 9.03641 11.7238L6.50173 9.44256C6.19385 9.16547 5.71963 9.19043 5.44254 9.49831C5.16544 9.80619 5.1904 10.2804 5.49828 10.5575L8.03296 12.8387C8.78809 13.5183 9.9613 13.4143 10.585 12.6123L14.592 7.46049Z" fill="#298700" />
                                </svg>}
                                {/* red cross svg ⬇ */}
                                {toast.title === 'Danger' && <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <g id="vuesax/bold/close-circle">
                                        <g id="close-circle">
                                            <path id="Vector" d="M12 2C6.49 2 2 6.49 2 12C2 17.51 6.49 22 12 22C17.51 22 22 17.51 22 12C22 6.49 17.51 2 12 2ZM15.36 14.3C15.65 14.59 15.65 15.07 15.36 15.36C15.21 15.51 15.02 15.58 14.83 15.58C14.64 15.58 14.45 15.51 14.3 15.36L12 13.06L9.7 15.36C9.55 15.51 9.36 15.58 9.17 15.58C8.98 15.58 8.79 15.51 8.64 15.36C8.35 15.07 8.35 14.59 8.64 14.3L10.94 12L8.64 9.7C8.35 9.41 8.35 8.93 8.64 8.64C8.93 8.35 9.41 8.35 9.7 8.64L12 10.94L14.3 8.64C14.59 8.35 15.07 8.35 15.36 8.64C15.65 8.93 15.65 9.41 15.36 9.7L13.06 12L15.36 14.3Z" fill="#FF0000" />
                                        </g>
                                    </g>
                                </svg>}
                                {/* Draft Mesg svg ⬇ */}
                                {toast.title === 'Draft' && <img src={Draft} />}

                                <p>{toast.description}</p>
                            </div>
                            <div className='undo_close' onClick={() => deleteToast(toastList?.[0]?.id)}>
                                {/* <a href="#" className="undobtn">Undo</a> */}
                                <button className="close-btn"><svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M10.2431 1.75738L1.75781 10.2427M10.2431 10.2426L1.75781 1.75732" stroke="#1E2D66" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                </svg>
                                </button>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </>
    );
}

export default memo(Toast);