import { ReactNode, Suspense } from 'react';
import Header from 'components/header/Header';
import SideMenu from 'components/side-menu/SideMenu';
import Loader from 'components/loader/Loader';
import useAdminUsers from 'hooks/store/admin-users/useAdminUsers';

type LayoutProps = {
    children: ReactNode
}

function Layout({ children }: LayoutProps) {
    const {addClass} = useAdminUsers();
    return (
        <div className={`wrapper flex ${addClass && 'open_menu'}`}>
            <SideMenu />
            <div className="main">
                <Header />
                <Suspense fallback={<Loader />}>
                    {children}
                </Suspense>
            </div>
        </div>
    )
}

export default Layout