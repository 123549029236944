import { create } from "zustand";

interface useLoginState {
  loginResponse: any;
  isError: boolean;
  isSessionExpired: boolean;
  expireTime: any;
  userProfilePic: string;
  setState(nextState: Partial<useLoginState>): void;
}

const useLoginStore = create<useLoginState>((set) => ({
  loginResponse: null,
  isError: false,
  isSessionExpired: false,
  expireTime: null,
  userProfilePic: "",
  setState: (nextState) => set(() => ({ ...nextState })),
}));

export default useLoginStore;
