import { useReducer } from 'react';
import axiosClient from "api/axiosClient";
import useToaster from 'hooks/store/toast/useToaster';
import usePartnersUser from 'hooks/store/partners/usePartnersUser';
import useAdminUsers from 'hooks/store/admin-users/useAdminUsers';

interface useDeleteSchoolState {
    isLoading: boolean,
    isSuccess: boolean,
    isError: boolean,
    data: {key: string, location: string | null},
    error: any,
    isFile:boolean
}

const initialState = {
    isLoading: false,
    isSuccess: false,
    isError: false,
    data: {key: '', location: null,},
    error: null,
    isFile:false
}

const reducer = (state: useDeleteSchoolState, payload: Partial<useDeleteSchoolState>) => ({ ...state, ...payload });

export default function useLogoFileUpload() {
    const [state, setState] = useReducer(reducer, initialState);
    const { setState:stateAdmin, isProfleUpload } = useAdminUsers();
    const toaster = useToaster();
    const uploadLogoHandler = async (req: any,isAdmin?:boolean) => {
        setState({ isLoading: true })
        try {
            const data = await axiosClient.post('file/fileUpload', req?.isPair ? req?.formData : req).then((res) => res.data);
            if (!data.error) {
                setState({ isLoading: false, isSuccess: true, data: data.data});
                isProfleUpload && toaster.showToast('Success', data.msg)
                stateAdmin({isProfleUpload:false})
                return;
            }
            toaster.showToast('Danger', data.msg)
        } catch (error: any) {
            if(error.response.status !== 401 ){
                toaster.showToast('Danger', error?.response?.data?.msg)
            }
            setState({ isError: true, error, isLoading:false });
        }
    }

    const resetHandler = () => setState({ ...initialState});

    return {
        ...state,
        uploadLogoHandler,
        resetHandler
    }
}
