import { optionsProps, PaginationType } from "../../../types";
import { create } from "zustand";

interface Sort {
  headerType: string;
  sortType: string;
}

interface useSocketMessageStore {
  socketData: any;
  setState(nextState: Partial<useSocketMessageStore>): void;
}
export const initinalSokectValues = {
  socketConnected: false,
  socketLoaders: {
    join: false,
    getMsgList: false,
    sendMsg: false,
    getMsg: false,
    getNotifications: false,
    chatLoader: false,
  },
  socket: {},
  connectionList:[],
  receiverDetail:null,
  messageList:{},
  chatId:'',
  imageUploadLoader:false,
  getNotificationCount:0,
  notificationResponse : null,
  isRecording:false,
  imageMessageData:{},
  replyBackMessage:null,
}
//getState: () => get(),
const useSocketStore = create<any>((set: any, get:any) => ({
  ...initinalSokectValues,
  getState: () => get(),
  setState: (nextState: any) => set(() => ({ ...nextState })),
}));

export default useSocketStore;
