import { ToastProps } from 'types';
import { create } from 'zustand';

export const TOAST_PROPERTIES: ToastProps[] = [
    {
        id: Math.floor((Math.random() * 101) + 1),
        title: 'Success',
        description: 'This is a success toast component',
        backgroundColor: '#5cb85c',
    },
    {
        id: Math.floor((Math.random() * 101) + 1),
        title: 'Danger',
        description: 'This is an error toast component',
        backgroundColor: '#d9534f',
    },
    {
        id: Math.floor((Math.random() * 101) + 1),
        title: 'Info',
        description: 'This is an info toast component',
        backgroundColor: '#5bc0de',
    },
    {
        id: Math.floor((Math.random() * 101) + 1),
        title: 'Warning',
        description: 'This is a warning toast component',
        backgroundColor: '#f0ad4e',
    },
    {
        id: Math.floor((Math.random() * 101) + 1),
        title: 'Draft',
        description: 'This is an draft toast component',
        backgroundColor: '#F3F9FF',
    },
];
interface useToastStore {
    list: ToastProps[];
    showToast: (value: "Success" | "Danger" | "Info" | "Warning" | "Draft", message: string) => void;
    removeAllToast(nextState: Partial<useToastStore>): void;
}

const useToaster = create<useToastStore>((set) => ({
    list: [],
    showToast: (value, message) => {
        const toastProperties = TOAST_PROPERTIES.find((toast) => toast.title === value);
        set((state: any) => ({ list: [...state.list, { ...toastProperties, description: message }] }), false,)
    },
    removeAllToast:(nextState:any) => set(() => ({ ...nextState })),
}));


export default useToaster;
