import { useEffect, useReducer } from 'react';
import axiosClient from "api/axiosClient";
import useToaster from 'hooks/store/toast/useToaster';
import useAddAdminUsers from 'hooks/store/add-admin-users/useAddAdminUsers';

interface useGetRegionsState {
    isLoading: boolean,
    isSuccess: boolean,
    isError: boolean,
    data: any,
    error: any
}

const initialState = {
    isLoading: false,
    isSuccess: false,
    isError: false,
    data: null,
    error: null
}

const reducer = (state: useGetRegionsState, payload: Partial<useGetRegionsState>) => ({ ...state, ...payload });

export default function useGetAdminUser() {
    const [state, setState] = useReducer(reducer, initialState);
    const { setState : setUpdate } = useAddAdminUsers();
    const toaster = useToaster();

    const getAdminUser = async (userId: string) => {
        setState({ isLoading: true })
        try {
            const data = await axiosClient.get(`/admin/getAdminUser?userId=${userId}`).then((res) => res.data)
            setState({ isSuccess: true, data })
            setUpdate({adminUser : data})
            // toaster.showToast("Success", data.msg)
        } catch (error: any) {
            setState({ isError: true, error })
            toaster.showToast("Danger", error.response.data.msg)
        } finally {
            setState({ isLoading: false });
        }
    }

    const resetResponse = () => setState({ ...initialState })

    return {
        ...state,
        getAdminUser,
        resetResponse
    }
}
