import { useState, useEffect, memo, ReactNode } from 'react';

interface NoInternetConnectionProps {
    children: ReactNode
}

const NoInternetConnection = ({ children }: NoInternetConnectionProps): JSX.Element => {

    const [isOnline, setOnline] = useState(true);

    useEffect(() => {
        setOnline(navigator.onLine)
    }, [])

    window.addEventListener('online', () => {
        setOnline(true)
    });

    window.addEventListener('offline', () => {
        setOnline(false)
    });

    if (isOnline) return (<>{children}</>);

    else return (
        <div className="i_popup i_requestInfo_popup i_msg_preview i_new_broadcast_message">
            <div className='i_popup_content'>
                <div className='i_popup_header d_flex_between border-bottom'>
                    Internet connection lost
                </div>
            </div>
        </div>
    )

}

export default memo(NoInternetConnection);