import { useEffect,useReducer } from 'react';
import axiosClient from "api/axiosClient";
import useToaster from 'hooks/store/toast/useToaster';

interface useGetUserState {
    isLoading: boolean,
    isSuccess: boolean,
    isError: boolean,
    data: any,
    error: any
}

const initialState = {
    isLoading: true,
    isSuccess: false,
    isError: false,
    data: null,
    error: null
}

const reducer = (state: useGetUserState, payload: Partial<useGetUserState>) => ({ ...state, ...payload });

export default function useSettings() {
    const [state, setState] = useReducer(reducer, initialState);
    const toaster = useToaster();

    const fetchProfile = async () => {
        setState({ isLoading: true })
        try {
            const data = await axiosClient.get('/admin/profileDetails').then((res) => res.data);
            if (!data.error) {

                setState({ isSuccess: true, data: data.data });

                return;
            }
            toaster.showToast('Danger', data.msg)
        } catch (error) {
            setState({ isError: true, error });
        }
    }

    useEffect(() => {
        fetchProfile();
    },[])

    return {
        ...state,
        fetchProfile,
    }
}
