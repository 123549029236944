import { cookieDecryption } from "utils";
import { memo, Suspense } from "react";
import { HelmetProvider } from "react-helmet-async";
import { useLocation, Navigate, Outlet } from "react-router-dom";
import Loader from "components/loader/Loader";
import { ErrorBoundary } from "react-error-boundary";
import ErrorBoundryFallback from "components/error-boundry-fallback/ErrorBoundryFallback";

interface PublicRouterProps {
    title: string
}

const PublicRouter = ({ title }: PublicRouterProps) => {
    const auth = cookieDecryption('user');
    const location = useLocation();

    let redirectPath = '/admin-users';

    switch (auth?.role) {

        case 'Super Admin': redirectPath = '/admin-users'; break;
        case 'Partner Super Admin': redirectPath = '/admin-users'; break;

        default: redirectPath = '/mentors'; break;
    }

    return !auth ?
        (
            <Suspense fallback={<Loader />}>
                <HelmetProvider context={{}}>
                    <title>{title}</title>
                    <ErrorBoundary FallbackComponent={ErrorBoundryFallback}>
                        <Outlet />
                    </ErrorBoundary>
                </HelmetProvider>
            </Suspense>
        )
        :
        <Navigate to={redirectPath} state={{ from: location }} replace />
};

export default memo(PublicRouter);