import { memo } from 'react';
import { RouterProvider, createBrowserRouter, BrowserRouter as Router, Routes, Route, createRoutesFromElements } from 'react-router-dom';
import AddAdminUser from 'views/add-admin-user/AddAdminUser';
import UnAuthorized from 'views/unauthorized/UnAuthorized';
import { privateRouteList, publicRouteList } from '.';
import PrivateRouter from './PrivateRouter';
import PublicRouter from './PublicRouter';

function RoutesComponent() {

    const render = () => {
        return (
            <>
                {/* Private Route List */}
                {privateRouteList.map(({ title, path, allowedRoles, element: Element,isHideLayout }) => (
                    <Route element={<PrivateRouter allowedRoles={allowedRoles} title={title} isHideLayout={!!isHideLayout} />}>
                        <Route path={path} element={<Element />} />
                    </Route>
                ))}

                {/* Public Route List  */}
                {publicRouteList.map(({ title, path, element: Element }) => (
                    <Route element={<PublicRouter title={title} />}>
                        <Route path={path} element={<Element />} />
                    </Route>
                ))}

                <Route path={'/*'} element={<UnAuthorized />} />
            </>
        )
    }

    const router = createBrowserRouter(createRoutesFromElements(render()));

    return <RouterProvider router={router} />

}

export default memo(RoutesComponent)