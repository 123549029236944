import { Country, Region } from 'types';
import { create } from 'zustand';

interface useAddAdminUsersStore {

    isCountryModal: boolean;
    selectedCountry: Country;
    addPartnerModal: boolean;
    partnersList: String[] | [];
    editRegion: any;
    assignedSchoolModal: any;
    adminUser : any;

    setIsCountryModal(): void;
    setSelectedCountry(nextSelectedCountry: Country): void;
    setIsAddPartnerModal(): void;
    addNewPartner(newPartner: any): void;
    setPartnerList(partnersList: any): void;
    setEditRegion(editRegion: any): void;
    setAssignedSchoolModal(nextAssignedSchool: any): void;

    setState(nextState: Partial<useAddAdminUsersStore>): void;
}

const INITIAL_COUNTRY: Country = {
    cname: "United States",
    iso: "US",
    code: "1",
    mask: "(999)-999-9999",
    svg: "/static/media/us.203f7809a52343ae52f7.svg",
    placeholder: "(___)-___-____"
}

export const initialAddAdminUsers = {
    isCountryModal: false,
    selectedCountry: INITIAL_COUNTRY,
    addPartnerModal: false,
    partnersList: [],
    editRegion: null,
    assignedSchoolModal: null,
    adminUser: null
}

const useAddAdminUsers = create<useAddAdminUsersStore>((set) => ({

    ...initialAddAdminUsers,

    setIsCountryModal: () => set(({ isCountryModal }) => ({ isCountryModal: !isCountryModal })),
    setSelectedCountry: (selectedCountry) => set({ selectedCountry }),
    setIsAddPartnerModal: () => set(({ addPartnerModal }) => ({ addPartnerModal: !addPartnerModal })),
    addNewPartner: (newPartner) => set(({ partnersList }: any) => {
        const nextNewPartner = partnersList?.find(({ value }: { value: string }) => value === newPartner?.value);  
        return { partnersList: partnersList ? nextNewPartner ? [...partnersList] :  [...partnersList, newPartner] : [newPartner] }
    }),
    setPartnerList: (partnersList) => set(() => ({ partnersList })),
    setEditRegion: (editRegion) => set(() => ({ editRegion: editRegion })),
    setAssignedSchoolModal: (nextAssignedSchool) => set(() => ({ assignedSchoolModal: nextAssignedSchool })),

    setState: (nextState) => set(() => ({ ...nextState })),

}));


export default useAddAdminUsers;

