import RoutesComponent from 'routes/data/RoutesComponent';
import SafeHydrate from 'components/safe-hydrate/SafeHydrate';
import NoInternetConnection from 'components/no-internet-connection/NoInternetConnection';
import { useEffect } from 'react';
import { cookieDecryption, cookieEncryption, encryption } from 'utils';
import useSocketStore from "./hooks/query/messages/useSocket";
import useSocketMessage from "./hooks/store/messages/useSocketStore";
import useLoginStore from 'hooks/store/common/useLoginStore';
// @ts-ignore
import TagManager from 'react-gtm-module'
import SessionExpiredPopup from "./modal/sessionExpired/SessionExpired";
import moment from "moment";
import Cookies from "universal-cookie";

const App = () => {
  const { isSessionExpired, expireTime } = useLoginStore()
  const socketData = useSocketMessage();
  const { state, getSocket, socketResponse }: any = useSocketStore();
  const user = cookieDecryption('user');
  const { loginResponse } = useLoginStore();

  useEffect(() => {
    getSocket({ token: user?.token, userData: user });
  }, [loginResponse]);
  
  const tagManagerArgs = {
    gtmId: "GTM-PFF836CC",
  };
  
  useEffect(()=>{
    fetchIpAddress()
     TagManager.initialize(tagManagerArgs);
  },[])

  useEffect(() => {
    if (Object.keys(socketData.socket || {}).length) {
      socketData.socket.on("response", (response: any) => {
        socketResponse({ response })
      })
    }

    return () => {
      if (Object.keys(socketData.socket || {}).length) {
        socketData.socket.off("response", function (err: any) {
        })
      }
    }
  }, [socketData.socket])

  useEffect(() => {
    if(Object?.keys(user || {})?.length > 0){
      const tagManagerArgs = {
        gtmId: "GTM-PFF836CC",
        dataLayer: {
          user_type: user?.role?.toLowerCase(),
          user_org: user?.partnerName || user?.region,
          user_school: user?.role === 'Super Admin' ? '' : user?.school?.length > 0 ? user?.school?.join(',')?.toLowerCase() : ''
        }
      };
      TagManager.dataLayer(tagManagerArgs);
    }
  }, [loginResponse]);

  const fetchIpAddress = async () => {
    let ip = await fetch("https://geolocation-db.com/json/").then(response => response.json()).then(data => {
        return data
    })
    localStorage.setItem("ipAdd", ip?.IPv4)
    let encryptIp = encryption(ip)
    localStorage.setItem("ipInformation", encryptIp)
}
window.addEventListener('storage', function(e:any) {
  if (e.key === 'userStatus') {
      window.location.reload()
  }
});
  return (
    <SafeHydrate>
      {
        isSessionExpired &&
            <SessionExpiredPopup/>
      }
      <NoInternetConnection>
        <RoutesComponent />
      </NoInternetConnection>
    </SafeHydrate>
  )
};

export default App;
